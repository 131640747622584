import { TranslatableKey } from '@app/types/translatable.type';

const GoalStatusesDisplayOptions: { [key: string]: TranslatableKey } = {
    completed: 'performance.goalStatuses.completed',
    pending: 'performance.goalStatuses.pending',
    inProgress: 'performance.goalStatuses.inProgress',
    scheduled: 'performance.goalStatuses.scheduled',
    overdue: 'performance.goalStatuses.overdue',
    missed: 'performance.goalStatuses.missed',
};

//keys should always match apps/hr-angular/src/app/modules/performance/enums/status.enum.ts
const GoalStatusesDisplayOptionsById: { [key: number]: TranslatableKey } = {
    1: 'performance.goalStatuses.completed',
    2: 'performance.goalStatuses.pending',
    3: 'performance.goalStatuses.inProgress',
    4: 'performance.goalStatuses.scheduled',
    5: 'performance.goalStatuses.overdue',
    6: 'performance.goalStatuses.missed',
};

export { GoalStatusesDisplayOptions, GoalStatusesDisplayOptionsById };
