import { TranslatableKey } from '@app/types/translatable.type';

const ReviewStatusesDisplayOptions: { [key: string]: TranslatableKey } = {
    completed: 'performance.reviewStatuses.completed',
    pending: 'performance.reviewStatuses.pending',
    inProgress: 'performance.reviewStatuses.inProgress',
    scheduled: 'performance.reviewStatuses.scheduled',
    overdue: 'performance.reviewStatuses.overdue',
    missed: 'performance.reviewStatuses.missed',
};

//keys should always match apps/hr-angular/src/app/modules/performance/enums/status.enum.ts
const ReviewStatusesDisplayOptionsById: { [key: number]: TranslatableKey } = {
    1: 'performance.reviewStatuses.completed',
    2: 'performance.reviewStatuses.pending',
    3: 'performance.reviewStatuses.inProgress',
    4: 'performance.reviewStatuses.scheduled',
    5: 'performance.reviewStatuses.overdue',
    6: 'performance.reviewStatuses.missed',
};

export { ReviewStatusesDisplayOptions, ReviewStatusesDisplayOptionsById };
