import { TranslatableKey } from '@app/types/translatable.type';

const FeedbackTypesDisplayOptions: { [key: string]: TranslatableKey } = {
    positive: 'performance.feedbackTypes.positive',
    constructive: 'performance.feedbackTypes.constructive',
    negative: 'performance.feedbackTypes.negative',
};

const FeedbackVisibilityDisplayOptions: { [key: string]: TranslatableKey } = {
    public: 'performance.feedbackVisibilities.public',
    private: 'performance.feedbackVisibilities.private',
    manager: 'performance.feedbackVisibilities.manager',
    admin: 'performance.feedbackVisibilities.admin',
};

export { FeedbackTypesDisplayOptions, FeedbackVisibilityDisplayOptions };
